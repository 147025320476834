import "./remerciement.css";
import {useContext, useEffect} from "react";
import {ObjectContext} from "../../context/ObjectContext";

const RemerciementService = () => {

  const setFooterColor = useContext(ObjectContext).setFooterColor;

  useEffect(() => {
    setFooterColor("#010064");
  }, []);

  return (
    <div className="remerciement" style={{color:"#d2fa94"}}>
      <h1>Merci d'avoir utilisé Deepblock</h1>
      <p>Cliquez sur le bouton ci-dessous pour retourner sur la page d'accueil.</p>
      <button onClick={() => window.location.href = "/"}>Retour à la page d'accueil</button>
    </div>
  )
};

export default RemerciementService;
import React, { useContext, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useLocation, useNavigate } from "react-router-dom";
import "./Services.css";
import { ObjectContext } from "../../context/ObjectContext";
import {getStrapiData, sendStrapiEmail} from "../../request/strapi";
import {EmailRNSV, TextEmailRNSV} from "../../components/email/email-content/template/EmailRNSV";

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const Services = () => {

  const setFooterColor = useContext(ObjectContext).setFooterColor;

  const location = useLocation();
  const navigate = useNavigate();

  const [idService, setIdService] = useState(undefined);
  const serviceParam = location.search.replace("?", "") || "services";

  const [currentService, setCurrentService] = useState(null);
  const [otherServices, setOtherServices] = useState([]);
  const [formuleService, setFormuleService] = useState([]);
  const [selectedFormule, setSelectedFormule] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    Prenom: "",
    Nom: "",
    Civilite: "",
    Email: "",
    Telephone: "",
    Pays: "",
    Service: "",
    Prix: "",
    cvc: ""
  });

  useEffect(() => {
    setFooterColor("#e4e3d8");
  }, [setFooterColor]);

  useEffect(() => {
    switch (serviceParam) {
      case "services": setIdService( 1); break;
      case "depot-capital": setIdService(2); break;
      case "protection-des-creations": setIdService(3); break;
      case "service-de-signature": setIdService(4); break;
      case "cachet-serveur": setIdService(5); break;
      case "certificat-signature-qualifie-a-distance": setIdService(6); break;
      case "rnsv": setIdService(7); break;
      default: setIdService(1); break;
    }

    setFormData((prevData) => ({
      ...prevData,
      ["Service"]: serviceParam,
    }));
  }, [serviceParam]);

  useEffect(() => {
    if (serviceParam === "prochainement") {
      navigate(`/prochainement`, { replace: true });
    }
  }, [serviceParam, navigate]);

  useEffect(() => {
    if (!idService) return;
    getStrapiData("page-service-deepblocks/" + idService).then((response) => {
      setCurrentService(response.data.attributes);
    }).catch((error) => {
      console.error("Erreur lors du fetch du service:", error);
    });
  }, [idService]);

  useEffect(() => {
    getStrapiData("service-deepblocks").then((response) => {
      setOtherServices(response.data)
    }).catch((error) => {
      console.error("Erreur lors du fetch des services:", error);
    });
  }, []);

  useEffect(() => {
    if (!currentService) return;
    setFormuleService(currentService.Formules);
    setIsLoading(false);
  }, [currentService]);



  const handleFormuleSelection = (formule) => {
    setSelectedFormule(formule.Nom);
    setFormData((prevData) => ({
      ...prevData,
      formule: formule.Nom,
      Prix: (formule.Prix * 1.2).toFixed(2), // Mise à jour du prix de la formule
    }));
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formuleService.length === 0) {
      await handleSubmitNoFormule();
    } else {
      await handleSubmitWithFormule();
    }
  };

  const handleSubmitNoFormule = async () => {
    if (serviceParam === "rnsv") {
      await handleSubmitRNSV();
      return;
    }
    try {
      const response = await fetch(API_URL + "/api/contact/send", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            ...formData,
            Prix: null,
          }
        }),
      });
      if (response.ok) {
        navigate("/services/remerciement");
      } else {
        console.error("Erreur lors de l'envoi des données:", response.statusText);
      }
    } catch (error) {
      console.error("Erreur de réseau:", error);
    }
  };

  const handleSubmitRNSV = async () => {

    await fetch(API_URL + "/api/contacts", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          ...formData,
          Prix: null,
        }
      }),
    });

    const emailHTML = ReactDOMServer.renderToStaticMarkup(<EmailRNSV name={formData.Prenom + " " + formData.Nom}/>)
    const data = {
      to: formData.Email,
      subject: "RNSV deepblock",
      text: TextEmailRNSV,
      html: emailHTML
    }

    await sendStrapiEmail(data);
    navigate("/services/rnsv");
  };

  const handleSubmitWithFormule = async () => {
    try {
      const response = await fetch(API_URL + "/api/contact/send", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: formData,
        }),
      });
      if (response.ok) {
        const result = await response.json();
        window.location.href = result.data._links.checkout.href
      } else {
        console.error("Erreur lors de l'envoi des données:", response.statusText);
      }
    } catch (error) {
      console.error("Erreur de réseau:", error);
    }
  };

  // Handling CKEditor content to HTML
  const jsonToHtml = (content) => {
    const contentHTML = content.map((item) => {
      switch (item.type) {
        case "heading":
          return `<h${item.level}>${jsonToHtml(item.children)}</h${item.level}>`;
        case "paragraph":
          return `<p>${jsonToHtml(item.children).join('')}</p>`;
        case "text":
          let text = item.text;
          if (item.bold) text = `<strong>${text}</strong>`;
          if (item.italic) text = `<em>${text}</em>`;
          if (item.underline) text = `<u>${text}</u>`;
          if (item.strikethrough) text = `<s>${text}</s>`;
          if (item.code) text = `<code>${text}</code>`;
          return text;
        case "list":
          // Gère les listes ordonnées et non ordonnées
          const listTag = item.format === 'unordered' ? 'ul' : 'ol';
          return `<${listTag}>${item.children.map(item => {
            if (item.type === 'list-item') {
              return `<li>${jsonToHtml(item.children)}</li>`;
            }
            return '';
          }).join('')}</${listTag}>`;
        case 'link':
          return `<a href="${item.url}" target="_blank">${jsonToHtml(item.children).join('')}</a>`;
        case 'quote':
          return `<blockquote>${jsonToHtml(item.children).join('')}</blockquote>`;
        case 'code':
          return `<pre><code>${jsonToHtml(item.children).join('')}</code></pre>`;
        case 'image':
          const imgUrl = item.image && item.image.url ? item.image.url : '';
          const altText = item.image && item.image.alt ? item.image.alt : 'Image';
          return `<img src="${imgUrl}" alt="${altText}" />`;
        default:
          return null;
      }
    });
    return contentHTML;
  };


  // {serviceParam === "rnsv" && (
  //   <div className="services-main-rnsv-info">
  //     <p>Veuillez noter que ce service RNSV nécessite des informations supplémentaires. Assurez-vous de remplir tous les champs obligatoires.</p>
  //   </div>
  // )}

  if (!currentService || !otherServices.length) return <p>Chargement...</p>;
  return (
    <section className="services-container">
      {serviceParam === "depot-capital" && window.innerWidth <= 768 ?
        (
          <div className="services-depot-capital-mobile">
            <img className="services-depot-capital-mobile-img" src="/smartphone.png" alt="Smartphone barré"/>
            <h1 className="services-depot-capital-mobile-text">Service non disponible depuis votre Smartphone</h1>
            <a className="services-depot-capital-mobile-bouton" href="/">Accueil</a>
          </div>
        ) : (
          <div>
            <section className="services-intro">
              <div className="services-intro-left">
                {
                  currentService.Titre ? (
                    <h1 className="services-intro-titre" dangerouslySetInnerHTML={{ __html: jsonToHtml(currentService.Titre).join('') || "Erreur de chargement" }}/>
                  ) : (
                    <h1 className="services-intro-sous-titre">Erreur de chargement</h1>
                  )
                }
                {
                  currentService.Description ? (
                    <p className="services-intro-description" dangerouslySetInnerHTML={{ __html: jsonToHtml(currentService.Description).join('') || "Erreur de chargement" }}/>
                  ) : (
                    <p className="services-intro-description">Erreur de chargement</p>
                  )
                }
                {serviceParam === "service-de-signature" && (
                  <a target="_blank" className="service-de-signature-connexion" href="https://admin.deepblock.fr/login">Connexion</a>
                )}
              </div>
              <div className="services-intro-right">
                <img
                  className="services-intro-logo"
                  src={API_URL + currentService.Picto.data.attributes.url}
                  alt={currentService.Nom || "Service"}/>
                <h4 className="services-intro-nom">{currentService.Nom || "Nom du service"}</h4>
              </div>
            </section>
            {serviceParam === "cachet-serveur" && (
              <div className="services-intro-cachet-serveur-info">
                <div className="services-intro-cachet-serveur-paragraphe">
                  <h3 className="services-intro-cachet-serveur-sous-titre">Offrez une empreinte numérique à tous vos documents avec le Cachet Serveur de deepblock, une solution incontestable et infalsifiable pour renforcer la crédibilité de votre entreprise.</h3>
                  <p className="services-intro-cachet-serveur-text">Équivalent électronique du certificat, le Cachet Serveur est spécifiquement conçu pour les entités juridiques, assurant une valeur juridique solide pour une traçabilité optimale de vos flux documentaires tels que devis, factures, contrats, et plus encore. Chez DeepBlock, nous avons délibérément choisi de mettre à votre disposition uniquement des Cachets Serveurs Qualifiés eIDAS, garantissant ainsi une conformité aux normes les plus élevées en matière de sécurité et de validité juridique. Faites le choix de sceller vos documents avec notre solution et assurez-vous ainsi une authenticité et une intégrité inébranlables. Renforcez la confiance de vos partenaires commerciaux grâce à notre technologie.</p>
                  <p className="services-intro-cachet-serveur-text">Découvrez le Cachet Serveur, la solution incontournable pour optimiser l'efficacité de votre entreprise en matière de scellement de documents. Grâce à notre technologie et notre API performante, vous pouvez partager facilement votre Cachet Serveur personnalisé avec tous les départements de votre entreprise, où qu'ils soient dans le monde.</p>
                  <p className="services-intro-cachet-serveur-text">Optez dès aujourd'hui pour la simplicité et la sécurité en obtenant votre cachet serveur en ligne. Notre processus d'obtention rapide garantit la mise à disposition de votre cachet serveur qualifié en seulement 15 jours, assurant ainsi le plus haut niveau de sécurité sur le marché.</p>
                </div>
              </div>
            )}

            <section className="services-main">
              <h1 className="services-main-titre">{currentService.Description_Iframe || "Erreur de chargement"}</h1>
              {currentService.Iframe ? (
                <iframe src={currentService.Iframe} title={currentService.Nom} className="services-main-iframe"/>
              ) : (
                <form className="services-main-forms" action="services/remerciement" onSubmit={handleSubmit}>
                  <div className="services-main-placement-right">
                    <h2 className="services-main-forms-titre">Informations</h2>
                  </div>
                  <div className="services-main-champs-placement">
                    <input
                      className="services-main-champs"
                      type="text"
                      name="Prenom"
                      placeholder="Prénom*"
                      value={formData.Prenom}
                      onChange={handleInputChange}
                      required/>
                    <input
                      className="services-main-champs"
                      type="text"
                      name="Nom"
                      placeholder="Nom de famille*"
                      value={formData.Nom}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="services-main-champs-placement">
                    <select
                      className="services-main-champs-civilite"
                      name="Civilite"
                      value={formData.Civilite}
                      onChange={handleInputChange}
                      required>
                      <option value="">Civilité*</option>
                      <option value="Homme">Homme</option>
                      <option value="Femme">Femme</option>
                    </select>
                    {serviceParam === "service-de-signature" && (
                      <div className="services-main-champs-cvc">
                        <input
                          className="services-main-champs"
                          type="text"
                          name="cvc"
                          placeholder="CVC"
                          value={formData.cvc}
                          onChange={handleInputChange}/>
                        <img className="services-main-cvc-gif" src="/vivatech.gif" alt="Vivatech"/>
                      </div>
                    )}
                  </div>
                  <div className="services-main-champs-placement">
                    <input
                      className="services-main-champs"
                      type="email"
                      name="Email"
                      placeholder="Email*"
                      value={formData.Email}
                      onChange={handleInputChange}
                      required/>
                    <input
                      className="services-main-champs"
                      type="tel"
                      name="Telephone"
                      placeholder="Numéro de téléphone*"
                      value={formData.Telephone}
                      onChange={handleInputChange}
                      required/>
                  </div>
                  <div className="services-main-champs-placement">
                    <select name="Pays" id="services-main-champs-pays" value={formData.Pays} onChange={handleInputChange} required>
                      <option value="">Choisissez un pays</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Albanie">Albanie</option>
                      <option value="Algérie">Algérie</option>
                      <option value="Andorre">Andorre</option>
                      <option value="Angola">Angola</option>
                      <option value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
                      <option value="Argentine">Argentine</option>
                      <option value="Arménie">Arménie</option>
                      <option value="Australie">Australie</option>
                      <option value="Autriche">Autriche</option>
                      <option value="Azerbaïdjan">Azerbaïdjan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahreïn">Bahreïn</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbade">Barbade</option>
                      <option value="Belgique">Belgique</option>
                      <option value="Bélize">Bélize</option>
                      <option value="Bénin">Bénin</option>
                      <option value="Bhoutan">Bhoutan</option>
                      <option value="Bolivie">Bolivie</option>
                      <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brésil">Brésil</option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgarie">Bulgarie</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cabo Verde">Cabo Verde</option>
                      <option value="Cambodge">Cambodge</option>
                      <option value="Cameroun">Cameroun</option>
                      <option value="Canada">Canada</option>
                      <option value="Centrafrique">Centrafrique</option>
                      <option value="Chad">Chad</option>
                      <option value="Chili">Chili</option>
                      <option value="Chine">Chine</option>
                      <option value="Colombie">Colombie</option>
                      <option value="Comores">Comores</option>
                      <option value="Congo-Brazzaville">Congo-Brazzaville</option>
                      <option value="Congo-Kinshasa">Congo-Kinshasa</option>
                      <option value="Corée du Nord">Corée du Nord</option>
                      <option value="Corée du Sud">Corée du Sud</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                      <option value="Croatie">Croatie</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Danemark">Danemark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominique">Dominique</option>
                      <option value="Égypte">Égypte</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Équateur">Équateur</option>
                      <option value="Émirats arabes unis">Émirats arabes unis</option>
                      <option value="Espagne">Espagne</option>
                      <option value="Estonie">Estonie</option>
                      <option value="Eswatini">Eswatini</option>
                      <option value="États-Unis">États-Unis</option>
                      <option value="Éthiopie">Éthiopie</option>
                      <option value="Fidji">Fidji</option>
                      <option value="Filipines">Filipines</option>
                      <option value="Finlande">Finlande</option>
                      <option value="France">France</option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambie">Gambie</option>
                      <option value="Géorgie">Géorgie</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Grèce">Grèce</option>
                      <option value="Grenade">Grenade</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinée">Guinée</option>
                      <option value="Guinée-Bissau">Guinée-Bissau</option>
                      <option value="Guinée équatoriale">Guinée équatoriale</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hongrie">Hongrie</option>
                      <option value="Islande">Islande</option>
                      <option value="Inde">Inde</option>
                      <option value="Indonésie">Indonésie</option>
                      <option value="Irak">Irak</option>
                      <option value="Iran">Iran</option>
                      <option value="Irlande">Irlande</option>
                      <option value="Islande">Islande</option>
                      <option value="Israël">Israël</option>
                      <option value="Italie">Italie</option>
                      <option value="Jamaïque">Jamaïque</option>
                      <option value="Japon">Japon</option>
                      <option value="Jordanie">Jordanie</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kirghizistan">Kirghizistan</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Koweït">Koweït</option>
                      <option value="Laos">Laos</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Lettonie">Lettonie</option>
                      <option value="Liban">Liban</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libye">Libye</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lituanie">Lituanie</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malaisie">Malaisie</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malte">Malte</option>
                      <option value="Marshall">Marshall</option>
                      <option value="Maurice">Maurice</option>
                      <option value="Mauritanie">Mauritanie</option>
                      <option value="Mexique">Mexique</option>
                      <option value="Micronésie">Micronésie</option>
                      <option value="Moldavie">Moldavie</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolie">Mongolie</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Namibie">Namibie</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Népal">Népal</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigéria">Nigéria</option>
                      <option value="Norvège">Norvège</option>
                      <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palaos">Palaos</option>
                      <option value="Panama">Panama</option>
                      <option value="Papouasie-Nouvelle-Guinée">Papouasie-Nouvelle-Guinée</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Pays-Bas">Pays-Bas</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pologne">Pologne</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Qatar">Qatar</option>
                      <option value="République centrafricaine">République centrafricaine</option>
                      <option value="République dominicaine">République dominicaine</option>
                      <option value="République tchèque">République tchèque</option>
                      <option value="Roumanie">Roumanie</option>
                      <option value="Royaume-Uni">Royaume-Uni</option>
                      <option value="Russie">Russie</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Samoa">Samoa</option>
                      <option value="Sao Tomé-et-Principe">Sao Tomé-et-Principe</option>
                      <option value="Arabie saoudite">Arabie saoudite</option>
                      <option value="Sénégal">Sénégal</option>
                      <option value="Serbie">Serbie</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapour">Singapour</option>
                      <option value="Slovaquie">Slovaquie</option>
                      <option value="Slovénie">Slovénie</option>
                      <option value="Somalie">Somalie</option>
                      <option value="Soudan">Soudan</option>
                      <option value="Soudan du Sud">Soudan du Sud</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Suède">Suède</option>
                      <option value="Suisse">Suisse</option>
                      <option value="Syrie">Syrie</option>
                      <option value="Tadjikistan">Tadjikistan</option>
                      <option value="Tanzanie">Tanzanie</option>
                      <option value="Tchad">Tchad</option>
                      <option value="Thaïlande">Thaïlande</option>
                      <option value="Togo">Togo</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinité-et-Tobago">Trinité-et-Tobago</option>
                      <option value="Tunisie">Tunisie</option>
                      <option value="Turkménistan">Turkménistan</option>
                      <option value="Turquie">Turquie</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vatican">Vatican</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viêt Nam">Viêt Nam</option>
                      <option value="Zambie">Zambie</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>
                  {serviceParam === "rnsv" && (
                    <div className="services-main-rnsv-info">
                      <p>Si vous êtes déjà inscrit à ce service, vous pouvez cliquer <a className="services-main-rnsv-lien" href="/services/rnsv">ICI</a> pour utiliser votre code.</p>
                    </div>
                  )}

                  {formuleService.length > 0 && (
                    <div>
                      <div className="services-main-placement-right">
                        <h2 className="services-main-forms-titre">Paiement</h2>
                      </div>
                      <div className="services-main-forms-paiement">
                        <span>
                          <input
                            type="radio"
                            id="mollie"
                            name="payment"
                            value="cb"
                            onChange={handleInputChange}
                            checked
                          />
                          <label className="services-main-forms-paiement-label" htmlFor="mollie">Mollie</label>
                        </span>
                        <img className="services-main-forms-paiement-img" src="/Mollie.webp" alt="Mollie"/>
                      </div>

                      <div className="services-main-placement-right">
                        <h2 className="services-main-forms-titre">Formules</h2>
                      </div>
                      <div className="services-main-placement-right">
                        {isLoading ? (<p>Chargement...</p>) :
                          (
                            <div className="services-main-formules-options">
                              {formuleService.map((formule, index) => (
                                <div key={index} className="services-formule-item">
                                  <div>
                                    <label className="services-main-formules-simple" htmlFor={`formule-${formule.Nom}`}>
                                      <div>
                                        <input
                                          type="radio"
                                          id={`formule-${formule.Nom}`}
                                          name="formule"
                                          value={formule.Nom}
                                          className="services-main-formule-radio"
                                          onChange={() => handleFormuleSelection(formule)}
                                        />
                                        <span className="services-main-formules-nom">{formule.Nom}</span>
                                      </div>
                                      <span className="services-main-formules-prix">{formule.Prix}€</span>
                                    </label>
                                  </div>
                                  {selectedFormule === formule.Nom && (
                                    <div>
                                      <div className="services-main-formules-taxes">
                                        <span className="services-main-formules-nom-taxes">TVA (20%)</span>
                                        <span className="services-main-formules-prix">{(formule.Prix * 0.2).toFixed(2)}€</span>
                                      </div>
                                      <div className="services-main-formules-total">
                                        <span className="services-main-formules-nom-taxes">Total</span>
                                        <span className="services-main-formules-prix">{(formule.Prix * 1.2).toFixed(2)}€</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    </div>
                  )}

                  <div className="services-main-placement-right">
                    <input type="submit" value="Valider" className="services-main-submit"/>
                    {serviceParam === "cachet-serveur" && (
                      <div className="services-main-cachet-serveur-info">
                        <p>Délai d’obtention avec les pièces administratives à fournir sous 9 jours maximum, paramétrage et installation compris sur notre plateforme.
                          <br/><br/>
                          Renouvellement à prévoir, 3 mois avant la date anniversaire.</p>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </section>
            <section className="services-autres">
              <div className="services-autres-left">
                <h1 className="services-autres-titre">Découvrez nos autres services, tous disponibles en ligne.</h1>
                <p className="services-autres-description">Notre ADN repose sur la transparence et la sécurisation de tous les documents et sur la simplification des workflows les plus complexes.</p>
              </div>

              <section className="services-autres-grille">
                {otherServices.sort((a, b) => a.id - b.id).map((service) => (
                  <a
                    key={service.id}
                    className="services-autres-services"
                    href={`?${service.attributes.link}`}>
                    <img
                      className="services-autres-img"
                      src={API_URL + service.attributes.picto.data.attributes.url}
                      alt={service.attributes.name}/>
                    <div className="services-autres-texteService">
                      <h4 className="services-autres-titreService">
                        {service.attributes.name}
                      </h4>
                      <p className="services-autres-descriptionService">
                        {service.attributes.description_courte}
                      </p>
                    </div>
                  </a>
                ))}
              </section>
            </section>
          </div>
        )
      };
    </section>
  );
};

export default Services;

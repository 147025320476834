import React from 'react';

const EmailBodyRNSV = ({ Nom }) => {
  return (
    <div style={{ margin: 0, padding: 0, width: '100%', fontFamily: 'Helvetica, sans-serif' }}>
      <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{ backgroundColor: '#EEEEEE' }}>
        <tr>
          <td align="center">
            <table className="container" style={{borderCollapse:'collapse', maxWidth: '600px', margin: '0 auto', backgroundColor: '#ffffff', padding: '20px 45px' }}>
              <tr>
                <td className="txt_main" style={{ color: '#000000', lineHeight: '30px', fontSize: '14px', padding: "20px 45px" }}>
                    <p style={{ margin: 0, padding: 0 }}>Merci <strong>{Nom}</strong> d'avoir choisi notre service de Registre National des Signatures Visuelles !<br/><br/>
                    Vous pouvez dès maintenant utiliser notre service en cliquant <a href={"https://deepblock.eu/services/rnsv"}>ICI</a> et en y entrant le mot de passe suivant : <strong>rnsv@2023</strong></p>
                </td>
              </tr>
            </table>
        
          </td>
        </tr>
      </table>
    </div>
  );
};

export default EmailBodyRNSV;
import "./AboutUs.css";
import {useContext, useEffect} from "react";
import {ObjectContext} from "../../context/ObjectContext";

const AboutUs = () => {

    const setFooterColor = useContext(ObjectContext).setFooterColor;

    useEffect(() => {
        setFooterColor("#010064");
    }, []);

  const handleScroll = () => {
    var scroll = window.scrollY;
    var floater = document.getElementById("right");
    floater.style.top = 100 + scroll/2.5 + "px";
    if (scroll/2.5 > 420) {
      floater.style.top = 520 + "px";
    }
  }

  window.addEventListener("scroll", function(){
    handleScroll();
  });

  return (
    <div className="about-us-container">

      <div className="about-us-content-left" id="left">
        <div>
          <h1>Que faisons-nous ?</h1>
          <p>Créée en 2017 par <span>Christophe LEMÉE</span> (Ingénieur) et <span>Maître Jean-Philippe TOUATI</span> (Avocat au Barreau de Paris), deepblock est la fusion du Deep Learning et de Blockchain.</p>
          <p>La société est une Legaltech spécialisée dans la transformation numérique et la réalisation de système d’information sur-mesure pour les entreprises du monde juridique, mais pas exclusivement.</p>
          <p>Nous avons depuis 7 ans maintenant, conçus de nombreux modules fonctionnels, en lien avec notre ADN de transparence et de souveraineté numérique.</p>
          <p>Plusieurs de nos modules sont accessibles par API et sont consommés par des éditeurs de plateformes, mais aussi par des entreprises des secteurs Privées comme Publiques.</p>
          <p>Notre entreprise, se compose à date de 19 collaborateurs répartis sur 3 sites :</p>
          <div style={{display:"flex", flexDirection:"row"}}>
            <ul>
              <li>Notre siège</li>
              <p>69 place du Docteur Félix Lobligeois 75017 Paris</p>
              <li>Notre R&D</li>
              <p>1 rue Patry 92220 Bagneux</p>
              <li>Notre équipe Projet(s)</li>
              <p>10 Place Octogonale 77700 Chessy</p>
            </ul>
            <img width="200px" height="200px"  src="/signal-2024-06-24-162326_002.jpeg" alt="Signal QR Code" loading="lazy"/>
          </div>
        </div>
        <div className="company-awards">
          <h1>Leader des services de confiance numérique</h1>
          <p>1ère DLT « Distributed Ledger Technology » Blockchain Juridique de France, commercialise une plateforme de Signature Electronique conforme au règlement eIDAS, un générateur de piste d'audit fiable à valeur probante, l'archivage longue durée et la fourniture de cachets serveurs et de certificats numériques qualifiés à distance.</p>
          <ul>
            <li>Co-Fondateur de la Legaltech France Digitale</li>
            <li>Agréée UGAP</li>
            <li>Alumni du Village by CA depuis 2020</li>
            <li>Membre de la Frenchtech Marne-la-Vallée</li>
          </ul>
        </div>
        <div className="company-awards">
          <h1>Les récompenses de l'entreprise :</h1>
          <img className={"about-frise"} src="/6644844c3ada9_Frise.png" alt="Frise" loading="lazy" />
        </div>
      </div>
      <div className="about-us-content-right" id="right">&nbsp;
        <div className="profile-container">
          <img width="200px" src="/profil-clemee2025.jpg" alt="Christophe LEMÉE" loading="lazy"/>
          <h1>Christophe LEMÉE</h1>
          <div className="about-us-separator"/>
          <h2>Fondateur et président</h2>
          <div className="profile-info">
            <p>Intervenant Formateur</p>
            <p>MBA EXPERT STRATEGIE DIGITALE - UTEC MLV</p>
            <p>École Polytechnique GC2VR Saclay - CNRS.</p>
          </div>
          <div className="profile-info">
            <p>Diplômé de l'Ecole Polytechnique</p>
            <p>Paris-Saclay / Stratège de la Transformation Numérique</p>
          </div>
          <div className="profile-info">
            <p>Certifications :</p>
            <p>Sciences Po Paris / Biais Cognitifs</p>
            <p>HEC Paris / Data & IA</p>
            <p>MIT Boston / Expert Blockchain | Major de Promotion décerné par : Dr. Abel Sanchez et Prof. John Williams</p>
          </div>

        </div>
        <div className="profile-container">
          <img width="200px" src="/Jean.png" alt="Jean-Philippe TOUATI" loading="lazy"/>
          <h1>Jean-Philippe TOUATI</h1>
          <div className="about-us-separator"/>
          <h2>Associé</h2>
          <p>Avocat à Paris, DEA en droit des affaires.</p>
        </div>
        <div className="profile-container">
          <img width="200px" src="/Henri.png" alt="Henri de la MOTTE ROUGE" loading="lazy"/>
          <h1>Henri de la MOTTE ROUGE</h1>
          <div className="about-us-separator"/>
          <h2>Associé</h2>
          <p>Avocat à Paris, titulaire d'un Master 2 en NTSI et d'un Master 2 en Droit de l'Homme.</p>
        </div>
      </div>
      <div className="mobile-about-us-content-left">
        <div>
          <h1>Leader des services de confiance numérique</h1>
          <p>1ère DLT « Distributed Ledger Technology » Blockchain Juridique de France, commercialise une plateforme de Signature Electronique conforme au règlement eIDAS, un générateur de piste d'audit fiable à valeur probante, l'archivage longue durée et la fourniture de cachets serveurs et de certificats numériques qualifiés à distance.</p>
          <ul>
            <li>Co-Fondateur de la Legaltech France Digitale</li>
            <li>Agréée UGAP</li>
            <li>Alumni du Village by CA depuis 2020</li>
            <li>Membre de la Frenchtech Marne-la-Vallée</li>
          </ul>
        </div>
        <div>
          <h1>Les récompenses de l'entreprise :</h1>
          <img width="80%" src="/6644844c3ada9_Frise.png" alt="Frise" loading="lazy" />
        </div>
        <img width="100px" height="100px"  src="/signal-2024-06-24-162326_002.jpeg" alt="Signal QR Code" loading="lazy"/>
      </div>
    </div>
  )
}

export default AboutUs;
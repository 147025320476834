import React from 'react';
import EmailHeader from "./email-content/EmailHeader";
import EmailFooter from "./email-content/EmailFooter";

const Email = ({ children }) => {
  return (
    <body style={{ margin: 0, padding: 0, width: '100%', fontFamily: 'Helvetica, sans-serif' }}>
      <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{ backgroundColor: '#EEEEEE' }}>
        <tr>
          <td align="center">
            <table className="container" style={{borderCollapse:'collapse', maxWidth: '600px', margin: '0 auto', backgroundColor: '#ffffff', padding: '20px 45px' }}>
              <EmailHeader/>
              <tr>
                <td className="txt_main" style={{ color: '#000000', lineHeight: '30px', fontSize: '14px', padding: "20px 45px" }}>
                  {children}
                </td>
              </tr>
            </table>
            <EmailFooter/>
          </td>
        </tr>
      </table>
    </body>
  );
};

export default Email;
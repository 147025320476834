import React from "react";


const EmailHeader = () => {
  return (
    <tr>
      <td className="center" style={{textAlign: "center"}}>
        <img
          id="logo"
          src="https://d1yei2z3i6k35z.cloudfront.net/1996590/6655d30f90827_logofondblanc.jpg"
          alt="Logo deepblock"
          style={{ maxWidth: '350px', padding: '0 10px' }}
        />
      </td>
    </tr>
  )
}

export default EmailHeader;
import "./rnsv.css";
import { ObjectContext } from "../../../context/ObjectContext";
import { useContext, useEffect } from "react";
import { toast } from "react-hot-toast";

const RNSV = () => {
  const setFooterColor = useContext(ObjectContext).setFooterColor;

  useEffect(() => {
    setFooterColor("#00004d");

    toast(
      (t) => (
        <span className="toast">
          <h1>Vous y êtes presque</h1>
          <p className="toast-text">Consultez votre boîte mail. Votre mot de passe vient tout juste de vous être envoyé par e-mail. Si vous ne trouvez pas notre e-mail, vérifiez vos spams. Si vous ne le trouvez toujours pas, contactez-nous à l'adresse suivante : <a className="toast-mail" href="mailto:contact@deepblock.eu">contact@deepblock.eu</a>.</p>
          <button className="toast-bouton" onClick={() => toast.dismiss(t.id)}>Fermer</button>
        </span>
      ),
      {
        duration: Infinity, 
      }
    );
  }, [setFooterColor]);

  return (
    <div className="rnsv-container">
      <iframe className="rnsv-iframe" src="https://ia.deepblock.fr/rnsv" />
    </div>
  );
};

export default RNSV;

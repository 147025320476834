import "./Prochainement.css";
import {ObjectContext} from "../../context/ObjectContext";
import {useContext, useEffect} from "react";

const Prochainement = () => {

  const setFooterColor = useContext(ObjectContext).setFooterColor;

  useEffect(() => {
    setFooterColor("#010064")
  }, []);

  return (
    <div className="prochainement-container">
      <div className="prochainement">
        <div className="prochainement-top">
          <img className="prochainement-picto" src="/pictoconstruction.svg" alt=""/>
          <h1 className="prochainement-titre">Ce contenu est en<br/>cours de <span className="jaune">fabrication !</span></h1>
        </div>
        <a href="/.."><button className="prochainement-bouton">Retour page d'accueil</button></a>
      </div>
    </div>
  )
}

export default Prochainement;
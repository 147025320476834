

const PlateformeDeSignature = () => {
  return (
    <div style={{width:"100%", height:"100vh", zIndex:"400"}}>
      <embed
        src={"/GuideUtilisateurSignatureSimple2024deepblock.pdf"}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default PlateformeDeSignature
import "./mentions-legal.css"

const MentionsLegal = () => {
    return (
        <div className="mention-legal-container">
          <h1>MENTIONS LEGALES</h1>
          <ol>
            <li>EDITION</li>
            <p>Ce site est édité par Deep Block SAS, SIRET : 829 773 670, dont le siège social est situé au 69 Place du Docteur Félix Lobligeois 75017 Paris (France).</p>
            <p>Le présent site est hébergé par SIO.</p>
            <p>Directeur de la publication : Christophe LEMÉE (CEO)</p>
            <p>Toute question, demande ou réclamation peut être adressée à l’adresse suivante : <a href="mailto:contact@deepblock.eu">contact@deepblock.eu</a></p>
            <p>Deep Block SAS est soumise aux lois en vigueur sur le territoire français.</p>
            <p>La plateforme des services SaaS en ligne dédiée à la gestion de la Signature Electronique « Simple, Avancée et Qualifiée » au sein de la définition eIDAS pour les entreprises.</p>
            <li>HÉBERGEMENT</li>
            <p>Les serveurs de la plateforme sont eux géographiquement localisés en France (Orléans et Vélizy pour le site du PCA).</p>
            <p>Pour plus d’informations sur le stockage des données, veuillez-nous contacter.</p>
            <li>CNIL</li>
            <p>Conformément à la loi Informatique et Liberté 78-17 du 6 janvier 1978 modifiée, vous disposez d’un droit d’opposition (art. 38), d’accès (art. 39), de rectification (art. 40) des données qui vous concernent.</p>
            <p>Envoyez-nous vos demandes :</p>
            <p>par voie postale : Deep Block (VitalSign), 69 Place du Docteur Félix Lobligeois 75017 Paris</p>
            <p>par voie électronique : Nous contacter (à l'aide du formulaire)</p>
            <p>Deep Block respecte la plus grande confidentialité, en particulier des messages transmis par voie électronique.</p>
            <p>Nous utilisons des cookies afin d’assurer le bon déroulement de votre visite et de réaliser des statistiques d’audience.</p>
            <li>PROPRIÉTÉ INTELLECTUELLE</li>
            <p>Les icônes et illustrations utilisées sur ce site web sont issus du service FOTOLIA, SIO et de l'entreprise Deep Block.</p>
            <p>L’ensemble de ce site relève de la législation française et internationale sur le droit d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques. Les visuels d’illustration de ce site sont propriété de l’Éditeur. Les droits d’utilisation, de reproduction et de diffusion sont strictement réservés à la communication de l’Éditeur. La reproduction de tout ou partie de ce site sur un support électronique ou papier quel qu’il soit est formellement interdite sauf autorisation expresse du responsable du site. Pour d’autres utilisations, veuillez contacter le responsable du site. Les marques citées sur ce site sont déposées par les sociétés qui en sont propriétaires.</p>
            <li>POLITIQUE DE CONFIDENTIALITE ET TRAITEMENT DE DONNEES</li>
            <p>Dans le cadre de la plateforme, le Client confie à Deep Block la réalisation d’un certain nombre de traitements pour son compte.</p>
            <p>Sauf instructions contraires du Client, la Solution n’implique pas de transfert de données à caractère personnel en dehors du l’Union Européenne.</p>
            <p>Deep Block traite ces informations selon les instructions du Client, pour les finalités des traitements réalisés dans le cadre de l’utilisation de la plateforme.</p>
            <p>Deep Block ne pourra en aucun cas exploiter les données personnelles collectées et traitées pour ses propres besoins, en particulier les données des utilisateurs de la plateforme aux fins de prospection commerciale.</p>
            <p>Deep Block n’effectue aucun contrôle sur la conformité de la politique de confidentialité établie par le Client à l’attention de ses cocontractants et il appartient exclusivement au Client de s’acquitter de ses obligations d’information à l’égard des personnes concernées. Le Client garantit Deep Block contre tout recours au titre de ces obligations d’information.</p>
            <p>Si Deep Block considère qu’une instruction constitue une violation de la réglementation applicable susvisée, il en informe le Client ou, le cas échéant, le tiers revendeur du Service auprès du Client.</p>
            <p>Deep Block s’interdit de divulguer ou de permettre la divulgation des données à caractère personnel à des tiers, sauf convention contraire ou autorisation expresse du Client. Deep Block garantit la confidentialité des données à caractère personnel traitées et veille à ce que les personnes autorisées à traiter les données s’engagent à respecter la confidentialité ou soient soumises à un accord de confidentialité, et qu’elles ont la formation nécessaire en matière de protection des données.</p>
            <p>Deep Block est soumise à une obligation de transparence et de traçabilité des traitements qu’elle effectue pour le compte du Client sur les données personnelles des personnes physiques qui lui sont confiées ou dont Deep Block constitue des fichiers dans le cadre de l’exécution de la Licence et ses éventuelles suites.</p>
            <p>A la demande du Client, Deep Block fournira une liste exhaustive de l’ensemble de ses propres sous-traitants et tous destinataires de données dans le cadre de l’exécution de la Licence.</p>
            <p>Dans la mesure du possible, Deep Block aide le Client à s’acquitter de son obligation de donner suite aux demandes d’exercice des droits des personnes concernées : droit d’accès, de rectification, d’effacement et d’opposition, droit à la limitation du traitement, droit à la portabilité des données, droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage).</p>
            <p>Deep Block adresse au Client sans délai toute demande qui serait formulée auprès de lui par une personne concernée par les traitements effectués comme sous-traitant du Client. Deep Block n’est pas chargée de répondre aux personnes concernées pour le compte du Client.</p>
            <p>Deep Block notifie au Client toute violation de données à caractère personnel dans un délai maximum de 24 heures après en avoir pris connaissance. Cette notification est accompagnée de toute documentation utile afin de permettre au Client, si nécessaire, de notifier cette violation à l’autorité de contrôle compétente ainsi qu’aux personnes concernées, le cas échéant et de prendre les mesures requises par la réglementation applicable. Deep Block n’est pas chargée de cette notification pour le compte du Client.</p>
            <p>Le Client garantit Deep Block contre toute action, de quelque nature qu’elle soit, pouvant être intentée contre Deep Block par une personne concernée ou encore tout tiers pour non-respect de la réglementation en matière de protection des données à caractère personnel imputable au Client au titre de ses obligations en tant que responsable de traitement, ou en raison d’un manquement aux conditions du Service , qui serait commis par le Client et/ou par ses éventuels autres sous-traitants et partenaires susceptibles d’intervenir dans le cadre du Service du fait du Client.</p>
          </ol>
        </div>
    )
}

export default MentionsLegal
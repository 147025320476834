import React from "react";
import { ObjectContext } from "./ObjectContext";

export const ObjectContextProvider: React.FC = ({ children }) => {
  const [footerColor, setFooterColor] = React.useState(undefined);

  return (
    <ObjectContext.Provider value={{ footerColor, setFooterColor }}>
      {children}
    </ObjectContext.Provider>
  )
}
import "./remerciement.css";
import {useContext, useEffect} from "react";
import {ObjectContext} from "../../context/ObjectContext";

const RemerciementContact = () => {

  const setFooterColor = useContext(ObjectContext).setFooterColor;

  useEffect(() => {
    setFooterColor("#010064");
  }, []);

  return (
    <div className="remerciement" style={{color:"#d2fa94"}}>
      <h1>Merci de nous avoir contactés</h1>
      <p>Nous avons pris en compte votre demande et nous reviendrons vers vous dans les plus brefs délais.</p>
      <button onClick={() => window.location.href = "/"}>Retour à la page d'accueil</button>
    </div>
  )
}

export default RemerciementContact
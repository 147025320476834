import Email from "../../Email";
import EmailBodyRNSV from "../email-content-body/EmailBodyRNSV";

export const EmailRNSV = ({name = "default"}) => {
  return (
    <Email>
      <EmailBodyRNSV Nom={name}/>
    </Email>
  );
}

export const TextEmailRNSV = ({name = "default"}) => {
  return `Merci ${name} d'avoir choisi notre service de Registre National des Signatures Visuelles !\n` +
    "\n" +
    "Vous pouvez dès maintenant utiliser notre service en cliquant [ICI](https://www.deepblock.eu/services/rnsv) et en y entrant le mot de passe suivant : rnsv@2023\n" +
    "\n" +
    "Éditeur\n" +
    "Deep Block SAS | 69, place du Docteur Félie Lobligeois – 75017 Paris | SIRET : 829 773 670\n" +
    "Directeur de la publication\n" +
    "Christophe LEMÉE, Président Fondateur de la société Deep Block.\n" +
    "Hébergement\n" +
    "Les serveurs sont géographiquement localisés en France (Orléans et Vélizy pour le site du PCA).\n" +
    "Pour plus d'informations sur le stockage des données, veuillez-vous nous contacter.\n" +
    "CNIL\n" +
    "Conformément à la loi Informatique et Liberté 78-17 du 6 janvier 1978 modifiée, vous disposez d'un droit d'opposition (art. 38), d'accès (art. 39), de rectification (art. 40) des données qui vous concernent.\n" +
    "Envoyez nous vos demandes\n" +
    "Par voie postale : Deep Block 69, place du Docteur Félie Lobligeois – 75017 Paris\n" +
    "Par voie électronique : Nous contacter (contact@deepblock.eu)\n" +
    "Deep Block respecte la plus grande confidentialité, en particulier des messages transmis par voie électronique.\n" +
    "Nous utilisons des cookies afin d'assurer le bon déroulement de votre visite et de réaliser des statistiques d'audience.\n" +
    "\n" +
    "© 2024 - Deep Block - Fabriqué en France\n" +
    "\n" +
    "[Désinscription](https://www.deepblock.eu/s/subscription/unsubscribe/test/1996590)"
}
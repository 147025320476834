import "./Accueil.css";
import {getStrapiData} from "../../request/strapi";
import {useContext, useEffect, useState} from "react";
import {ObjectContext} from "../../context/ObjectContext";
import Popup from "../../components/popup/Popup";

const Accueil = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const setFooterColor = useContext(ObjectContext).setFooterColor;

  useEffect(() => {
    setFooterColor("#d2fa94")
    fetchServiceData();
  }, [])

  const fetchServiceData = async () => {
    await getStrapiData('service-deepblocks').then((response) => {
      setData(response.data);
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
    })
  }

  const serviceElement = () => {
    if (data === undefined) {
      if (isLoading) {
        return <p>Chargement en cours...</p>
      }
      return <p>Aucun service trouvé</p>
    }
    return (
      data.sort((a, b) => a.id - b.id).map((service) => {
        return (
          <div className="accueil-services-content-data" key={service.id}>
            <a href={"/services?" + service.attributes.link}>
              <div className="service-picto">
                {
                  service.attributes.picto.data !== null ?
                    <img src={API_URL + service.attributes.picto.data.attributes.url} alt={service.attributes.name}/>
                    :
                    <img src="https://via.placeholder.com/150" alt="placeholder"/> // Placeholder image
                }
              </div>
              <h4 className="service-nom">{service.attributes.name || 'Nom indisponible'}</h4>
            </a>
            <p className="service-description">{service.attributes.description || 'Description indisponible'}</p>
          </div>
        )
      })
    )
  }

  return (
      <div className="accueil-container">

        <Popup/>
        <section className="accueil-intro">
          <div className="accueil-intro-content">
            <h1 className="accueil-intro-content-title">deepblock Legaltech</h1>
            <div className="content-intro-content-p1">
              <p>Génération et Conservation des Preuves.</p>
            </div>
            <div className="content-intro-content-p2">
              <img src="/N1.webp" alt="Numéro 1 entouré de lauriers"/>
              <p>Blockchain Juridique de France</p>
            </div>
            <div className="content-intro-content-p3">
              <p>"Il est très difficile de prouver l’inexistence de quelque chose,<br/>pour tout le reste, les clients les plus exigeants comptent sur nous".</p>
            </div>
            <div className="content-intro-content-p4">
              <p>Nos services respectent la réglementation eIDAS et notre plateforme de<br/>Signature Electronique utilise des certificats qualifiés émis par
                <span style={{color:"#010064"}} >.............................</span>
                <img src="/logo-entrust%20-%20Blanc.png" alt="Logo Entrust"/></p>
            </div>
            <div className="content-intro-content-p5">
              <p>Entrust, prestataire de service de confiance reconnu par l'Union Européenne, dont<br/>deepblock est partenaire : <a id="lien-entrust" href="https://www.entrust.com/partners/directory/deepblock">https://www.entrust.com/partners/directory/deepblock</a></p>
            </div>
          </div>
          <div className="accueil-intro-img">
            <div className="accueil-intro-img-left">
              <img className="accueil-intro-img-gauche" src="/femme_bras_croise_compress.webp" alt="logo" />
            </div>
            <div className="accueil-intro-img-right">
              <img className="accueil-intro-img-droite" src="/femme_homme_redimension.webp" alt="logo" />
            </div>
          </div>
        </section>

        <section id="services" className="accueil-services">
          <div className="section-header">
            <div className="section-header-title">
              <img className="section-header-logo" src="/Carrebleu.svg" alt="puce"/>
              <p className="section-header-name">Services</p>
            </div>
            <div className="section-header-description">
              <p>La souveraineté de <strong>vos données</strong></p>
              <p>est <strong>notre unique priorité.</strong></p>
              <p>Comme la conformité de notre</p>
              <p>société de <strong>droit français,</strong> face</p>
              <p>aux enjeux du <strong>RPGD</strong> et <strong>eIDAS 2.0</strong></p>
            </div>
          </div>
          <div className="mobile-section-header">
            <div className="section-header-title">
              <img className="section-header-logo" src="/Carrebleu.svg" alt="puce"/>
              <p className="section-header-name">Services</p>
            </div>
            <div className="section-header-description">
              <p>La souveraineté de <strong>vos données</strong> est <strong>notre unique priorité.</strong> Comme la conformité de notre société de <strong>droit français,</strong> face aux enjeux du <strong>RPGD</strong> et <strong>eIDAS 2.0</strong></p>
            </div>
          </div>

          <div className="accueil-services-content">
            {serviceElement()}
          </div>
        </section>


        <section id="technologies" className="accueil-technologies">
          <div className="section-header">
            <div className="section-header-title">
              <img className="section-header-logo" src="/Carrebleu.svg" alt="puce"/>
              <p className="section-header-name">Technologies</p>
            </div>
            <div className="section-header-description">
              <p>La plateforme <strong>deepblock</strong>,</p>
              <p>La référence du secteur juridique.</p>
            </div>
          </div>

          <div className="mobile-section-header">
            <div className="section-header-title">
              <img className="section-header-logo" src="/Carrebleu.svg" alt="puce"/>
              <p className="section-header-name">Technologies</p>
            </div>
            <div className="section-header-description">
              <p>La plateforme <strong>deepblock</strong>,</p>
              <p>La référence du secteur juridique.</p>
            </div>
          </div>

          <div className="accueil-technologies-content">
            <div className="accueil-technologies-content-image">
              <img src="/mockuptelrcompensesbeige.webp" alt="Nos dernières récompenses, Global 100 et Legal Awards 2023"/>
            </div>
            <div className="accueil-technologies-content-data">
              <div>
                <img src="/pictosignaturetechno.svg" alt="logo"/>
                <p>Vos cérémonies de Signature s'exécutent en France.</p>
              </div>
              <div>
                <img src="/pictotechnoscu.svg" alt="logo"/>
                <p>100% sécurisé avec notre protocole Blockchain "OpenLogs".</p>
              </div>
              <div>
                <img src="/pictotechnonuage.svg" alt="logo"/>
                <p>Archivage électronique dans notre SAE multi-sécurisé.</p>
              </div>
              <div>
                <img src="/pictotechnohotloge.svg" alt="logo"/>
                <p>Horodatage certifié eIDAS.</p>
              </div>
              <div>
                <img src="/pictotechnodatacenter.svg" alt="logo"/>
                <p>Vos fichiers sont hébergés et stockés en France sur 3 sites distincts.</p>
              </div>
            </div>
          </div>
        </section>


        <section className="accueil-inscription">
          <div className="section-header">
            <div className="section-header-title">
              <img className="section-header-logo" src="/Carrejaune.svg" alt="puce"/>
              <p className="section-header-name">Adopter notre platforme</p>
            </div>
          </div>

          <div className="mobile-section-header">
            <div className="section-header-title">
              <img className="section-header-logo" src="/Carrejaune.svg" alt="puce"/>
              <p className="section-header-name">Adopter notre platforme</p>
            </div>
          </div>

          <div className="accueil-inscription-content">
            <div className="accueil-inscription-content-left">
              <div>
                <p>Démarrez sans attendre<br/>
                votre première cérémonie<br/>
                de Signature eIDAS !</p>
              </div>
              <div>
                <a href={"/services?service-de-signature"}>
                  <button>Inscrivez-vous gratuitement</button>
                </a>
              </div>
            </div>
            <div className="accueil-inscription-content-right">
              <img src="/animservicesgratuits.gif" alt="logo"/>
            </div>
          </div>
        </section>


        <section className="accueil-partenaires">
          <div className="partenaires-content">
            <img src="/Commisaire-de-justice.webp" alt="logo commissionaire de justice"/>
            <img src="/mogador.png" alt="logo commissionaire de justice"/>
            <img src="/bizouard.webp" alt="logo commissionaire de justice"/>
            <img src="/Affiches-parisiennes.webp" alt="logo commissionaire de justice"/>
            <img src="/Européenne-de-biomasse.webp" alt="logo commissionaire de justice"/>
            <img src="/Karbonalpha.png" alt="logo commissionaire de justice"/>
            <img src="/Registre-general.webp" alt="logo commissionaire de justice"/>
            <img src="/Smartpreuve.webp" alt="logo commissionaire de justice"/>
          </div>
          <p>La confiance numérique partagée, depuis 2017.</p>
        </section>
      </div>
  );
}

export default Accueil;
import React from "react";

const EmailFooter = () => {
  return (

    <table className="container"
           style={{backgroundColor: "#010064", color: "#ffffff", fontSize: "13px", width: "100%", textAlign: "center"}}>
        <div
          style={{
            maxWidth: '600px',
            margin: '0 auto',
            backgroundColor: '#010064',
            padding: '20px 45px',
            color: '#ffffff',
            fontSize: '13px',
          }}
        >
          <p className="st_foot" style={{fontWeight: "bold"}}>Éditeur</p>
          <p className="txt_foot" style={{marginBottom: "25px", lineHeight:"30px"}}>Deep Block SAS | 69, place du Docteur Félie Lobligeois – 75017 Paris | SIRET : 829 773 670</p>
          <p className="st_foot" style={{fontWeight: "bold"}}>Directeur de la publication</p>
          <p className="txt_foot" style={{marginBottom: "25px", lineHeight:"30px"}}>Christophe LEMÉE, Président Fondateur de la société Deep Block.</p>
          <p className="st_foot" style={{fontWeight: "bold"}}>Hébergement</p>
          <p className="txt_foot" style={{marginBottom: "25px", lineHeight:"30px"}}>
            Les serveurs sont géographiquement localisés en France (Orléans et Vélizy pour le site du PCA).<br />
            Pour plus d'informations sur le stockage des données, veuillez nous contacter.
          </p>
          <p className="st_foot" style={{fontWeight: "bold"}}>CNIL</p>
          <p className="txt_foot" style={{marginBottom: "25px", lineHeight:"30px"}}>
            Conformément à la loi Informatique et Liberté 78-17 du 6 janvier 1978 modifiée, vous disposez d'un
            droit d'opposition (art. 38), d'accès (art. 39), de rectification (art. 40) des données qui vous
            concernent.
          </p>
          <p className="st_foot" style={{fontWeight: "bold"}}>Envoyez-nous vos demandes</p>
          <p className="txt_foot" style={{marginBottom: "25px", lineHeight:"30px"}}>
            Par voie postale : Deep Block 69, place du Docteur Félie Lobligeois – 75017 Paris<br />
            Par voie électronique :{' '}
            <a href="mailto:contact@deepblock.eu" style={{ color: '#D2FA94' }}>
              <strong>contact@deepblock.eu</strong>
            </a>
            <br /> Deep Block respecte la plus grande confidentialité, en particulier des messages transmis par
            voie électronique.<br /> Nous utilisons des cookies afin d'assurer le bon déroulement de votre visite
            et de réaliser des statistiques d'audience.
          </p>
          <div id="mentionlgl" style={{ fontWeight: 'bold', textAlign: 'center' }}>© 2024 - Deep Block - Fabriqué en France</div>
        </div>
    </table>



  )
}

export default EmailFooter;
import axios from "axios";

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL + "/api";

export const getStrapiData = (data) => {
  return axios.get(`${API_URL}/${data}?populate=*`, {
    headers: {
      Authorization: `Bearer ${API_KEY}`,
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    console.error(error);
  }).then((response) => {
    return response.data;
  });
}

export const sendStrapiData = (service, data) => {
  return axios.post(`${API_URL}/${service}`, {
    headers: {
      Authorization: `Bearer ${API_KEY}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).catch((error) => {
    console.error(error);
  }).then((response) => {
    return response.data;
  });
}

export const sendStrapiEmail = (data) => {
  return axios.post(`${API_URL}/email`, data, {
    headers: {
      Authorization: `Bearer ${API_KEY}`,
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    console.error(error);
  }).then((response) => {
    return response.data;
  });
}
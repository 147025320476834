import "./Header.css";
import {useState} from "react";



const Header = () => {

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const [popupOpenned, setPopupOpenned] = useState(false);

  const openPopup = () => {
    document.getElementById('popup').style.display = 'flex';
    setPopupOpenned(true);
  }

  const closePopup = () => {
    document.getElementById('popup').style.display = 'none';
  }

  const openMobileNavbar = () => {
    if (isNavbarOpen) {
      const navbar = document.getElementById('mobile-navbar')
      navbar.style.display = 'none';
      navbar.style.width = '0%'
      setIsNavbarOpen(false);
      animateNavbarBars()
      return;
    }
    const navbar = document.getElementById('mobile-navbar')
    navbar.style.display = 'flex';
    navbar.style.width = '40%'
    setIsNavbarOpen(true);
    animateNavbarBars()
  }

  const animateNavbarBars = () => {
    const bars = document.getElementsByClassName('navbar-bar');
    if (isNavbarOpen) {
      bars[0].style.transform = 'rotate(0deg)';
      bars[1].style.opacity = '1';
      bars[2].style.transform = 'rotate(0deg)';
      return;
    }
    bars[0].style.transform = 'rotate(45deg) translate(0px, -4px)';
    bars[1].style.opacity = '0';
    bars[2].style.transform = 'rotate(-45deg) translate(-1px, 2px)';
  }

  return (
    <div className="header-container">
      <section id="popup" className="popup" style={{ display: 'none' }}>
        <img className="croix" src="/croix.png" alt="close" onClick={closePopup} />
        {
          popupOpenned ? <iframe className="calendly" width="500px" height="750px" src="https://calendly.com/deepblock/15min"></iframe> : null
        }
      </section>

      <section className="header-section">
        <div className="header-logo">
          <a href="/" rel="noopener noreferrer" target="_self">
            <img src="/Logo.svg" alt="logo" />
          </a>
        </div>
        <div className="header-navbar">
          <div className="header-navbar-container">
            <a href={"/#services"}>Services</a>
            <div className="headbar-navbar-separator"></div>
            <a href={"/#technologies"}>Technologies</a>
            <div className="headbar-navbar-separator"></div>
            <a href={"/ressources"}>Ressources</a>
            <div className="headbar-navbar-separator"></div>
            <a href={"/actualite"}>Actualités</a>
            <div className="headbar-navbar-separator"></div>
            <a href={"/a-propos"}>Nous connaître</a>
          </div>
        </div>
        <div className="header-meet-button">
          <a>
            <button onClick={openPopup}>Prendre rendez-vous</button>
          </a>
        </div>
      </section>
      
      <section className="mobile-header-section">
        <div className="header-logo">
          <a href="/" rel="noopener noreferrer" target="_self">
            <img src="/Logo.svg" alt="logo" />
          </a>
          <div className="header-meet-button">
            <a>
              <button onClick={openPopup}>Prendre rendez-vous</button>
            </a>
          </div>
        </div>

        <div className="mobile-navbar-open-button" onClick={openMobileNavbar}>
          <div id="open">
            <div className="navbar-bar"/>
            <div className="navbar-bar"/>
            <div className="navbar-bar"/>
          </div>
        </div>
      </section>

      <div className="mobile-header-navbar" id="mobile-navbar">
          <a href={"/#services"}>Services</a>
          <a href={"/#technologies"}>Technologies</a>
          <a href={"/ressources"}>Ressources</a>
          <a href={"/actualite"}>Actualités</a>
          <a href={"/a-propos"}>Nous connaître</a>
      </div>

    </div>
  );
}

export default Header;

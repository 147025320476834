import "./Contact.css";
import {useContext, useEffect, useState} from "react";
import {ObjectContext} from "../../context/ObjectContext";

const Contact = () => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const setFooterColor = useContext(ObjectContext).setFooterColor;


  useEffect(() => {
    setFooterColor("#010064")
    fetchServiceData();
  }, [])

  const fetchServiceData = async () => {
  }

  // Fonction pour mettre à jour les sujets sélectionnés
  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      // Ajoute la valeur si la case est cochée
      setSelectedSubjects((prev) => [...prev, value]);
    } else {
      // Retire la valeur si la case est décochée
      setSelectedSubjects((prev) => prev.filter((subject) => subject !== value));
    }
  };

  // Génère la chaîne de texte pour l'input sujet
  const generateSubjectText = () => {
    if (selectedSubjects.length === 0) return "";
    return selectedSubjects.join(", ") + ".";
  };

  return (
    <section className="contact-container">
      <section className="contact-left">
        <h1 className="contact-title">Échangez avec l’un de nos experts 24/7.</h1>

        <div className="contact-under-title-section">
          <img className="contact-puce" src="/Carrejaune.svg" alt="Puce" />
          <h2 className="contact-under-title">
            Nous écoutons vos enjeux et vos projets qu'ils soient XS ou XXL, avec le sourire et enthousiasme.
          </h2>
        </div>

        <div>
          <hr />
          <p className="contact-text">Vous souhaitez soumettre une candidature spontanée ou nous proposer un partenariat ?</p>
          <br />
          <p className="contact-text">
            Envoyez-nous un courriel à l’adresse suivante :{" "}
            <a className="contact-mail" href="mailto:contact@deepblock.eu">
              contact@deepblock.eu
            </a>
          </p>
        </div>
      </section>

      <section className="contact-right">
        <form className="contact-form" name="contact-form" action="https://www.itcozen.com/send-sio.php" method="post">
          <p className="contact-form-texte">Nos experts vous présentent nos services, nos solutions et répondent à toutes vos questions.</p>
          <strong className="contact-form-texte">C'est gratuit et sans engagement.</strong>

          <div className="contact-form-placement">
            <input className="contact-form-input" type="text" name="nom" placeholder="Nom" required />
            <input className="contact-form-input" type="email" name="email" placeholder="Email" required />

            <input className="contact-form-input-subject" id="sujet" type="text" name="sujet" placeholder="Sujet" value={generateSubjectText()} readOnly />

            <p className="contact-form-subject">Sujet :</p>

            <div>
              <input
                type="checkbox"
                className="contact-form-checkbox"
                name="consent"
                value="Signature à l'acte"
                onChange={handleCheckboxChange}
              />
              <label className="contact-form-checkbox" htmlFor="Signature à l'acte">Signature à l'acte</label>
              <br />
              <input
                type="checkbox"
                className="contact-form-checkbox"
                name="consent"
                value="Plateforme de Signature"
                onChange={handleCheckboxChange}
              />
              <label className="contact-form-checkbox" htmlFor="Plateforme de Signature">Plateforme de Signature</label>
              <br />
              <input
                type="checkbox"
                className="contact-form-checkbox"
                name="consent"
                value="Api de signature"
                onChange={handleCheckboxChange}
              />
              <label className="contact-form-checkbox" htmlFor="Api de signature">Api de signature</label>
              <br />
              <input
                type="checkbox"
                className="contact-form-checkbox"
                name="consent"
                value="Cachet Serveur eIDAS"
                onChange={handleCheckboxChange}
              />
              <label className="contact-form-checkbox" htmlFor="Cachet Serveur eIDAS">Cachet Serveur eIDAS</label>
              <br />
              <input
                type="checkbox"
                className="contact-form-checkbox"
                name="consent"
                value="Délivrance de certificat de signature qualifié à distance"
                onChange={handleCheckboxChange}
              />
              <label className="contact-form-checkbox" htmlFor="Délivrance de certificat de signature qualifié à distance">
                Délivrance de certificat de signature qualifié à distance
              </label>
              <br />
              <input
                type="checkbox"
                className="contact-form-checkbox"
                name="consent"
                value="App. Windows"
                onChange={handleCheckboxChange}
              />
              <label className="contact-form-checkbox" htmlFor="App. Windows">App. Windows</label>
              <br />
              <input
                type="checkbox"
                className="contact-form-checkbox"
                name="consent"
                value="Dépôt de capital"
                onChange={handleCheckboxChange}
              />
              <label className="contact-form-checkbox" htmlFor="Dépôt de capital">Dépôt de capital</label>
              <br />
              <input
                type="checkbox"
                className="contact-form-checkbox"
                name="consent"
                value="Propriété intellectuelle"
                onChange={handleCheckboxChange}
              />
              <label className="contact-form-checkbox" htmlFor="Propriété intellectuelle">Propriété intellectuelle</label>
            </div>

            <textarea className="contact-form-textarea" name="messageBody" placeholder="Message" required></textarea>
          </div>

          <div className="contact-form-footer">
            <p className="contact-form-footer-txt">
              Ou vous pouvez profiter dès à présent de notre enthousiasme au <strong>+33 7 81 35 61 15</strong>.
            </p>
            <input className="contact-form-submit" type="submit" value="Envoyez" />
          </div>
          <p className="contact-form-mention">
            En cliquant sur "Envoyer", vous acceptez notre politique de confidentialité. Pour en savoir plus sur l'utilisation de vos données personnelles, consultez notre politique de confidentialité.
          </p>
        </form>
      </section>
    </section>
  );
};

export default Contact;

import "./Popup.css";
import React, {useEffect} from 'react';
import PopupSignatureADistance from "./Popup-signature-a-distance";
import {getStrapiData} from "../../request/strapi";
import PopupStrapi from "./Popup-strapi";

const Popup = () => {
  const [data, setData] = React.useState([]);
  const [popupNumber, setPopupNumber] = React.useState(-1);
  const lastPopup = localStorage.getItem('lastPopup');
  const API_URL = process.env.REACT_APP_API_URL;
  const POPUP_INTERVAL = 1000 * 60 * 10;
  const popupInterval = localStorage.getItem('popupInterval');


  useEffect(() => {
    fetchPopupData();
  }, []);

  const fetchPopupData = async () => {
    if (popupInterval !== null) {
      const currentTime = new Date().getTime();
      if (currentTime - popupInterval < POPUP_INTERVAL) {
        return;
      }
    }
    await getStrapiData("popups").then(response => {
      setData(response.data);
    });
  }


  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    choosePopupNumber();
  }, [data]);

  const choosePopupNumber = () => {
    if (popupNumber !== -1) {
      return;
    }
    let randomNumber = Math.floor(Math.random() * (data.length + 1));
    if (lastPopup !== null) {
      if (lastPopup === randomNumber.toString()) {
        randomNumber = (randomNumber + 1) % (data.length + 1);
      }
    }
    localStorage.setItem('lastPopup', randomNumber);
    setPopupNumber(randomNumber);
  }


  const displayPopup = () => {
    if (data.length === 0 || popupNumber === -1) {
      return null;
    }
    if (popupNumber === 0) {
      return <PopupSignatureADistance />;
    }
    const popup = data[popupNumber - 1]
    const popupVisuel = API_URL + popup.attributes.Visuel.data.attributes.url;
    const popupLien = popup.attributes.Lien;
    return <PopupStrapi src={popupVisuel} href={popupLien ? popupLien : ""} />;
  }

  return (
    <div id="cvc-popup">
      {displayPopup()}
    </div>
  )
}

export default Popup;